import moment from "moment";

export const formatData = (data) => {
  return data.map((item, index) => ({
    docNum: item.docNum,
    docEntry: item.docEntry,
    cardCode: item.cardCode,
    cardName: item.cardName,
    u_numberOfCntr: item.u_numberOfCntr,
    u_China_platform: item.u_China_platform,
    u_numberPlatformKzx: item.u_numberPlatformKzx,
    u_StationOfOperationRailway: item.u_StationOfOperationRailway,
    u_DateOfOperation:
      item.u_DateOfOperation !== null
        ? moment(item.u_DateOfOperation).format("DD.MM.YYYY")
        : "",
    u_LineOfOperation: item.u_LineOfOperation,
    u_DestinationStation: item.u_DestinationStation,
    u_Remaining_km: item.u_Remaining_km,
    u_DispatchPlan:
      item.u_DispatchPlan !== null
        ? moment(item.u_DispatchPlan).format("DD.MM.YYYY")
        : "",
    u_DateSending:
      item.u_DateSending !== null
        ? moment(item.u_DateSending).format("DD.MM.YYYY")
        : "",
  }));
};
